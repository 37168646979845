@import "yet-another-react-lightbox/styles.css";

@font-face {
  font-family: "Basier Circle";
  font-weight: 600;
  src: url("/static/fonts/basiercircle-semibold-webfont.eot");
  src: url("/static/fonts/basiercircle-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/basiercircle-semibold-webfont.woff2") format("woff2"),
    url("/static/fonts/basiercircle-semibold-webfont.woff") format("woff"),
    url("/static/fonts/basiercircle-semibold-webfont.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Basier Circle";
  font-weight: 400;
  src: url("/static/fonts/basiercircle-regular-webfont.eot");
  src: url("/static/fonts/basiercircle-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts/basiercircle-regular-webfont.woff2") format("woff2"),
    url("/static/fonts/basiercircle-regular-webfont.woff") format("woff"),
    url("/static/fonts/basiercircle-regular-webfont.ttf") format("truetype");
  font-style: normal;
}

html {
  min-height: 100%;
  min-height: -webkit-fill-available;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}

.fade-exit,
.fade-enter,
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms linear;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

body > [data-rk] {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
